import Grid from '@mui/material/Grid';
import React from 'react';
import { ViewRequestPanelContentDocumentsHeader, ViewRequestPanelContentDocumentsList } from './components';

const ViewRequestPanelContentDocuments = () => {
  return (
    <Grid item container direction="column" gap={5} wrap="nowrap" paddingBottom={5}>
      <ViewRequestPanelContentDocumentsHeader />
      <ViewRequestPanelContentDocumentsList />
    </Grid>
  );
};

export default ViewRequestPanelContentDocuments;
