import { FileStatusEnum, myocean__schemas__files__FileSchema } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { FileDashed, ImagesSquare } from '@phosphor-icons/react';
import IconButtonWithTooltip from 'components/IconButtonWithTooltip';
import { FormattedMessage } from 'components/Intl';
import { upperFirst } from 'lodash-es';
import isNil from 'lodash-es/isNil';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from 'pages/backoffice/Modals/command';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  file: myocean__schemas__files__FileSchema;
  buttonsDisabled: boolean;
}

const ViewRequestPanelContentDocumentsItemFile = (props: IProps) => {
  const { file, buttonsDisabled } = props;

  const dispatch = useDispatch();
  const styles = useStyles(file.status);

  const handleOpenFilePreview = () => {
    const fileLinks: (string | undefined)[] = [];

    /*
     * Если файл является PDF, то у него есть child_files, которые содержат ссылки на превью в формате JPG.
     * При этом параметр link у файла PDF содержит ссылку на сам файл в формате PDF.
     * Поэтому если есть child_files, то используем их ссылки, если их нет, то ссылку самого файла
     */

    file.child_files?.forEach((file) => {
      if (!isNil(file.child_files.length) && file.child_files.length > 0) {
        const childFilesLinks = file.child_files.map((childFile) => childFile.link);

        fileLinks.push(...childFilesLinks);
      } else {
        fileLinks.push(file.link);
      }
    });

    if (fileLinks.length === 0) {
      fileLinks.push(file.link);
    }

    dispatch(openBackofficeModal(new ImageCarouselModalData(fileLinks, file.document_id, file.status)));
  };

  let status = (
    <Typography fontSize={12}>
      <FormattedMessage id="label.being_digitized" />
    </Typography>
  );

  switch (file.status) {
    case FileStatusEnum.RETURNED:
      status = (
        <Typography fontSize={12} color={COLOURS.Strawberry.Solid[600]}>
          <FormattedMessage id="label.returned" />
          :&nbsp;
          {upperFirst(file.reason_of_return?.replace(/_/g, ' '))}
        </Typography>
      );
      break;
    default:
      break;
  }

  return (
    <Grid item container sx={styles.container}>
      <Grid item container gap={3} width="auto" wrap="nowrap">
        <Grid item>
          <SvgIcon fontSize="large">
            <FileDashed />
          </SvgIcon>
        </Grid>

        <Grid item container direction="column" gap={2} wrap="nowrap">
          <Typography fontWeight="semiBold">{file.filename}</Typography>

          {status}
        </Grid>
      </Grid>

      <Grid item container alignItems="center" width="auto">
        <IconButtonWithTooltip
          onClick={handleOpenFilePreview}
          icon={<ImagesSquare />}
          disabled={buttonsDisabled}
          tooltipLabel="label.open_source_file"
          ariaLabel="aria.label.open_file_preview"
        />
      </Grid>
    </Grid>
  );
};

export default ViewRequestPanelContentDocumentsItemFile;
