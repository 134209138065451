import { FileStatusEnum } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { VIEW_PANEL_FILE_ITEM_GRADIENT_ANGLE } from 'pages/backoffice/Requests/consts';

export default (status?: FileStatusEnum): Record<string, SxProps<Theme>> => ({
  container: {
    justifyContent: 'space-between',
    padding: 3,
    flexWrap: 'nowrap',
    border: `1px solid ${
      status === FileStatusEnum.RETURNED ? COLOURS.Strawberry.Solid[300] : COLOURS.Coconut.Solid[200]
    }`,
    borderRadius: 1,
    background: `repeating-linear-gradient(
      ${VIEW_PANEL_FILE_ITEM_GRADIENT_ANGLE}deg,
      ${COLOURS.WHITE},
      ${COLOURS.WHITE} 10px,
      ${status === FileStatusEnum.RETURNED ? COLOURS.Strawberry.Solid[50] : COLOURS.Coconut.Solid[50]} 10px,
      ${status === FileStatusEnum.RETURNED ? COLOURS.Strawberry.Solid[50] : COLOURS.Coconut.Solid[50]} 20px
    )`,
  },
});
