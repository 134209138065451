import { IDeclineDocumentModalData } from '@/pages/backoffice/Modals/DeclineDocumentModal/actions';
import { PARENT_PAGE } from 'pages/backoffice/Modals/consts';

export default class DeclineDocumentModalData implements IDeclineDocumentModalData {
  id: number;
  parentPage: PARENT_PAGE;
  requestId?: number;
  documentPaid?: boolean;

  constructor(id: number, parentPage: PARENT_PAGE, requestId?: number, documentPaid?: boolean) {
    this.id = id;
    this.parentPage = parentPage;
    this.requestId = requestId;
    this.documentPaid = documentPaid;
  }
}
