import { ApproveStatusEnum } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (props: { status: string | undefined }): Record<string, SxProps<Theme>> => ({
  approveButton: {
    minWidth: 'auto',
    borderRadius: '16px !important',
    cursor: `${props.status === ApproveStatusEnum.APPROVED ? 'default' : 'pointer'} !important`,

    color: `${COLOURS.Avocado.Solid[600]} !important`,

    background: `${
      props.status === ApproveStatusEnum.APPROVED ? COLOURS.Avocado.Solid[100] : COLOURS.WHITE
    } !important`,

    borderColor: `${
      props.status === ApproveStatusEnum.APPROVED ? COLOURS.Avocado.Solid[600] : COLOURS.Coconut.Solid[200]
    } !important`,

    '&:hover': {
      color: `${
        props.status === ApproveStatusEnum.APPROVED ? COLOURS.Avocado.Solid[600] : COLOURS.Avocado.Solid[700]
      } !important`,

      borderColor: `${
        props.status === ApproveStatusEnum.APPROVED ? COLOURS.Avocado.Solid[700] : COLOURS.Avocado.Solid[600]
      } !important`,
    },
  },

  declineButton: {
    minWidth: 'auto',
    borderRadius: '16px !important',
    cursor: `${props.status === ApproveStatusEnum.DECLINED ? 'default' : 'pointer'} !important`,

    color: `${COLOURS.Strawberry.Solid[600]} !important`,

    background: `${
      props.status === ApproveStatusEnum.DECLINED ? COLOURS.Strawberry.Solid[100] : COLOURS.WHITE
    } !important`,

    borderColor: `${
      props.status === ApproveStatusEnum.DECLINED ? COLOURS.Strawberry.Solid[600] : COLOURS.Coconut.Solid[200]
    } !important`,

    '&:hover': {
      color: `${
        props.status === ApproveStatusEnum.DECLINED ? COLOURS.Strawberry.Solid[600] : COLOURS.Strawberry.Solid[700]
      } !important`,

      borderColor: `${
        props.status === ApproveStatusEnum.DECLINED ? COLOURS.Strawberry.Solid[600] : COLOURS.Strawberry.Solid[700]
      } !important`,
    },
  },
});
