import { useAppSelector } from '@/hooks/useAppSelector';
import { declineDocument } from '@/pages/backoffice/Modals/DeclineDocumentModal/actions';
import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import TextField from '@core-ui/text_field';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Prohibit } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const DeclineDocumentModal = (props: IProps) => {
  const { opened, onClose } = props;

  const { id, parentPage, requestId, documentPaid, loading } = useAppSelector(selector);
  const [declineReason, setDeclineReason] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(declineDocument({ id, parentPage, requestId, declineReason }));
  };

  useEffect(() => {
    setDeclineReason('');
  }, [opened]);

  return (
    <Modal
      open={opened}
      maxWidth="xs"
      onClose={onClose}
      title={<FormattedMessage id="modal.backoffice.decline_document.title" />}
      subtitle={documentPaid ? <FormattedMessage id="modal.backoffice.decline_paid_document.subtitle" /> : undefined}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            multiline
            autoFocus
            minRows={4}
            maxRows={9}
            label={<FormattedMessage id="modal.backoffice.decline_document.decline_reason" />}
            fullWidth
            value={declineReason}
            // @ts-ignore
            onChange={setDeclineReason}
          />

          <DialogActions>
            <Button
              color="blue"
              size="large"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={loading}
              label={<FormattedMessage id="label.decline" />}
              startIcon={<Prohibit />}
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Modal>
  );
};

export default DeclineDocumentModal;
